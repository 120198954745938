import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const config = {
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
};

const app = initializeApp(config);

const messaging = getMessaging(app);

export default messaging;
