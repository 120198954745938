export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_ACCOUNT_LIST = 'SET_ACCOUNT_LIST';
export const SET_ALL_INVESTMENTS_PRICE = 'SET_ALL_INVESTMENTS_PRICE';
export const SET_BANK_TRANSACTION = 'SET_BANK_TRANSACTION';
export const SET_ALL_ACCOUNTS_TRANSACTIONS = 'SET_ALL_ACCOUNTS_TRANSACTIONS';
export const SET_FILTERED_INVESTMENTS = 'SET_FILTERED_INVESTMENTS';
export const SET_PAYEE_LIST = 'SET_PAYEE_LIST';
export const SET_INVESTMENT_TRANSACTIONS = 'SET_INVESTMENT_TRANSACTIONS';
export const SET_INVESTMENT_ACCOUNT_TRANSACTIONS = 'SET_INVESTMENT_ACCOUNT_TRANSACTIONS';
export const SET_ACCOUNT_INVESTMENTS = 'SET_ACCOUNT_INVESTMENTS';
export const SET_MORTAGE_SCHEDULE = 'SET_MORTAGE_SCHEDULE';
export const SET_UPDATE_INVESTMENT_PRICE_FETCHING = 'SET_UPDATE_INVESTMENT_PRICE_FETCHING';
export const SET_LIFETIME_PLANNER_FLOW = 'SET_LIFETIME_PLANNER_FLOW';
export const SET_NET_WORTH_FLOW = 'SET_NET_WORTH_FLOW';
export const SET_MESSAGING_TOKEN = 'SET_MESSAGING_TOKEN';
export const REHYDRATE = 'REHYDRATE';
export const SET_NOTIFICATION_HISTORY = 'SET_NOTIFICATION_HISTORY';
export const SET_DIVIDEND_FETCHING = 'SET_DIVIDEND_FETCHING';
export const SET_WEEKLY_TRANSACTIONS = 'SET_WEEKLY_TRANSACTIONS';
export const SET_ADD_TRANSACTION_RESULT = 'SET_ADD_TRANSACTION_RESULT';
export const SET_ADD_TRANSACTION_FETCHING = 'SET_ADD_TRANSACTION_FETCHING';
export const SET_EDIT_TRANSACTION_RESULT = 'SET_EDIT_TRANSACTION_RESULT';
export const SET_EDIT_TRANSACTION_FETCHING = 'SET_EDIT_TRANSACTION_FETCHING';
export const SET_DELETE_TRANSACTION_RESULT = 'SET_DELETE_TRANSACTION_RESULT';
export const SET_DELETE_TRANSACTION_FETCHING = 'SET_DELETE_TRANSACTION_FETCHING';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_ALL_INVESTMENTS = 'SET_ALL_INVESTMENTS';
export const SET_HISTORY_LIST = 'SET_HISTORY_LIST';
export const ADD_ALL_ACCOUNTS_TRANSACTIONS = 'ADD_ALL_ACCOUNTS_TRANSACTIONS';
export const EDIT_ALL_ACCOUNTS_TRANSACTIONS = 'EDIT_ALL_ACCOUNTS_TRANSACTIONS';
export const DELETE_ALL_ACCOUNTS_TRANSACTIONS = 'DELETE_ALL_ACCOUNTS_TRANSACTIONS';
export const ADD_OR_EDIT_ALL_ACCOUNTS_TRANSACTIONS = 'ADD_OR_EDIT_ALL_ACCOUNTS_TRANSACTIONS';
export const SET_TRANSACTIONS_FETCHING = 'SET_TRANSACTIONS_FETCHING';
export const SET_SETTINGS = 'SET_SETTINGS';